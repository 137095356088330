import { DateRange } from 'react-day-picker';

export type GraphProps = {
  idealLimit: number;
};

export type LeastMostSummaryProps = {
  type: TimelineTypes;
  granularity: TimelineGranularity;
  finalRange: DateRange | undefined;
  data: Object[];
};

export enum TimelineTypes {
  Productivity = 'Productivity',
  TimeUse = 'Time use',
  Utilisation = 'Utilisation',
  Fuel = 'Fuel',
}

export enum TimelineGranularity {
  Hourly = 'Hours',
  Daily = 'Days',
  Monthly = 'Months',
}

export type TimelineKeys = {
  name: string;
  color: string;
  colorClass: string;
};

export type StartEndIndex = {
  start: number;
  end: number;
};

export type TimelineData = {
  _id: string;
  yAxis: number;
};

export type TimelineTimeUseAPIData = {
  xAxis: string;
  yAxis1: number;
  yAxis2: number;
};

export type TimeUse = {
  contactTime: number;
  nearbyTime: number;
};

export type TimeUseForDate = TimeUse & {
  date: string;
};

export type TimeUseForMonth = TimeUse & {
  month: string;
};

export type TimeUseForHour = TimeUse & {
  hour: string;
};

export type TimeUseTimeline =
  | TimeUseForDate[]
  | TimeUseForMonth[]
  | TimeUseForHour[];

export type Productivity = {
  pushes: number;
  speed: number;
};

export type ProductivityDataArray = {
  pushes: TimelineData[];
  speed: TimelineData[];
};

export type ProductivityForDate = Productivity & {
  date: string;
};

export type ProductivityForMonth = Productivity & {
  month: string;
};

export type ProductivityForHour = Productivity & {
  hour: string;
};

export type ProductivityTimeline =
  | ProductivityForDate[]
  | ProductivityForMonth[]
  | ProductivityForHour[];

export type UtilisationDataArray = {
  transmissionTime: TimelineData[];
  idleTime: TimelineData[];
  inactiveTime: TimelineData[];
};

export type Utilisation = {
  transmissionTime: number;
  idleTime: number;
  inactiveTime: number;
  tonnage: number;
};

export type UtilisationForDate = Utilisation & {
  date: string;
};

export type UtilisationForMonth = Utilisation & {
  month: string;
};

export type UtilisationForHour = Utilisation & {
  hour: string;
};

export type UtilisationTimeline =
  | UtilisationForDate[]
  | UtilisationForMonth[]
  | UtilisationForHour[];

export type Fuel = {
  fuel: number;
};

export type FuelForDate = Fuel & {
  date: string;
};

export type FuelForMonth = Fuel & {
  month: string;
};
